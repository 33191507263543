(function anonymous() {
   const NavigationMenu = {
      menuButton: document.querySelector('.menuButton'),
      navEl: document.querySelector('[data-nav]'),
      showMenu: false,
      initNavigation() {
         this.menuButton.addEventListener("click", event => {
            event.preventDefault();
            if (!this.showMenu) {
               this.navEl.style.right = "0";
               this.showMenu = true;
            } else {
               this.navEl.style.right = "-100%";
               this.showMenu = false;
            }
         });

         this.navEl.addEventListener("click", e => {
            if (e.target && e.target.tagName == "A") {
               this.navEl.style.right = "-100%";
               this.showMenu = false;
            }
         });
      }
   }

   NavigationMenu.initNavigation();
})();